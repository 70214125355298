:root {
  --background-color: #f6f6f6;
}

.ag-theme-alpine {
  --ag-header-background-color: #fcfcfc;
  --ag-odd-row-background-color: #fcfcfc;
  --ag-borders: none;
  --ag-borders-critical: 1px solid;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: var(--background-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

.ag-root-wrapper {
  border-radius: 8px;
}

.ag-cell-wrapper {
  height: 100%;
}

.ag-cell-value {
  height: 100%;
}
